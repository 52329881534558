<template>
  <div class="row PropertyListItem" :class="{ 'PropertyListItem--border': border }">
    <div :class="colSize">
      <span class="font-w600 text-muted no-wrap">
        <slot name="title">{{ title }}</slot>
      </span>
    </div>
    <div class="col" :class="{ 'text-right': alignRight }">
      <span :class="{ 'text-uppercase': uppercase, 'text-capitalize': capitalize }">
        <slot>{{ value }}</slot>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PropertyListItem',
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    value: {
      type: String,
      default: 'Value'
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    capitalize: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    colSize: {
      type: String,
      default: 'col-lg-6'
    },
    alignRight: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">
.PropertyListItem.PropertyListItem--border {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 8px;
}

.PropertyListItem:last-child.PropertyListItem--border {
  border-bottom: 0;
  padding-bottom: 0;
}
</style>
