<template>
  <div v-if="loadingAction.getCarbonProject" class="text-center pt-5">
    <Spinner />
  </div>
  <div v-else>
    <PageHeaderDisplay
      :title="carbon.project.name"
      :sub-title="carbon.project.shortDescription"
      category="Carbon Projects"
      category-logo="fa-trees"
      :category-route="{ name: 'marketplace-carbon-list' }"
    />
    <div class="content">
      <div class="row">
        <div class="col-md-7">
          <div class="mb-4">
            <img :src="carbon.project.images[0].url" class="image" />
          </div>
          <div>
            <p>{{ descriptionParagraphs[0] }}</p>
            <img v-if="carbon.project.images[1]" :src="carbon.project.images[1].url" class="image mb-4" />
            <p v-for="(paragraph, idx) in descriptionParagraphs.slice(1)" :key="idx">{{ paragraph }}</p>
            <div v-if="carbon.project.images.length > 2" class="image-list mb-5">
              <img v-for="image in carbon.project.images.slice(2)" :key="image.url" :src="image.url" class="image-sm" />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-5">
            <SectionTitle actions-class="col-md-4">
              <template>Purchase</template>
            </SectionTitle>
            <div class="mb-3">
              <div v-if="form.type === 'money'" class="input-group">
                <FormItem id="type" v-model="form.type" class="col-md-3" alt type="select" :options="purchaseTypeOptions" />
                <FormItem id="money" v-model="form.money" />
                <FormItem id="currency" v-model="form.currency" alt class="col-md-3" type="select" :options="currencyOptions" />
              </div>
              <div v-else class="input-group">
                <FormItem id="type" v-model="form.type" class="col-md-3" alt type="select" :options="purchaseTypeOptions" />
                <FormItem id="carbon" v-model="form.carbon" />
                <FormItem id="unit" v-model="form.unit" alt class="col-md-3" type="select" :options="carbonUnitOptions" />
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col">
                <span class="font-w600 font-size-lg mr-2">${{ purchaseTotals.money }} USD</span>
                <span class="text-muted text-capitalize">{{ purchaseTotals.carbon }} {{ form.unit }}</span>
                <p class="mb-0 small font-w600 text-muted">Vintage Year {{ carbon.project.activeTranche.vintageYear }}</p>
              </div>
              <div class="col-lg-4 text-right"><button class="btn btn-primary" @click="constructionModal = true">Add to Cart</button></div>
            </div>
          </div>
          <div class="mb-5">
            <SectionTitle>Project Details</SectionTitle>
            <PropertyListItem col-size="col-md-4" class="mb-3" title="Location"
              >{{ carbon.project.city }}, {{ carbon.project.country }}</PropertyListItem
            >
            <PropertyListItem col-size="col-md-4" class="mb-3" title="Methodology Type">{{ carbon.project.type }}</PropertyListItem>
            <PropertyListItem col-size="col-md-4" class="mb-3" title="Mechanism">{{ carbon.project.offsetClassification }}</PropertyListItem>
            <PropertyListItem col-size="col-md-4" class="mb-3" title="Registry">{{ carbon.project.registryName }}</PropertyListItem>
            <PropertyListItem col-size="col-md-4" class="mb-3" title="Project ID">{{ carbon.project.registryProjectId }}</PropertyListItem>
            <PropertyListItem col-size="col-md-4" class="mb-3" title="Ratings">
              <span v-for="projectRating in carbon.project.projectRatings" :key="projectRating.ratingTitle"
                ><strong class="mr-3">{{ projectRating.rating }}</strong> {{ projectRating.ratingTitle }}</span
              ><br />
            </PropertyListItem>
            <a class="text-primary font-w600" :href="carbon.project.registryLink"
              >Registry Listing <i class="fa fa-arrow-up-right-from-square fa-sm"></i
            ></a>
          </div>
          <SectionTitle>Location</SectionTitle>
          <GoogleMap :map-config="mapOptions" :markers="mapMarkers" />
        </div>
      </div>
    </div>
    <ConfirmModal title="Coming Soon" :open="constructionModal" hide-submit @close="constructionModal = false">
      <div class="text-center">
        <i class="fad fa-triangle-person-digging text-primary fa-4x mb-3"></i>
        <p class="font-w600">We are still working on the ability to self-service purchase carbon projects through Etainabl.</p>
        <p>
          In the meantime, please contact us at <a href="mailto:support@etainabl.com">support@etainabl.com</a>, and we can help you purchase a carbon
          project.
        </p>
      </div>
    </ConfirmModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import FormItem from '@/components/FormItem';
import GoogleMap from '@/components/GoogleMap';
import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import PropertyListItem from '@/components/base/PropertyListItem';
import SectionTitle from '@/components/base/SectionTitle';
import Spinner from '@/components/Spinner';

export default {
  name: 'MarketplaceCarbonView',
  components: {
    ConfirmModal,
    FormItem,
    GoogleMap,
    PageHeaderDisplay,
    PropertyListItem,
    SectionTitle,
    Spinner
  },
  data() {
    return {
      loading: true,
      search: '',
      projectType: null,
      constructionModal: false,
      form: {
        money: 0,
        carbon: 0,
        currency: 'usd',
        type: 'carbon',
        unit: 'tonnes'
      },
      purchaseTypes: {
        money: 'Money',
        carbon: 'Carbon'
      },
      carbonUnitOptions: [
        { label: 'tonnes', value: 'tonnes' },
        { label: 'kg', value: 'kg' },
        { label: 'lbs', value: 'lbs' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      errorAction: 'marketplace/errorAction',
      loadingAction: 'marketplace/loadingAction',
      carbon: 'marketplace/carbon'
    }),
    descriptionParagraphs() {
      return this.carbon.project.longDescription.split('\r\n\r\n');
    },
    purchaseTypeOptions() {
      return Object.entries(this.purchaseTypes).map(([k, v]) => ({ label: v, value: k }));
    },
    currencyOptions() {
      return [{ label: 'USD', value: 'usd' }];
    },
    purchaseTotals() {
      if (this.form.type === 'money') {
        return {
          money: this.form.money,
          carbon: (this.form.money * 100 * (this.carbon.project.totalCostPerKgCarbonInUsdCents / 1000)).toFixed(2)
        };
      } else {
        let tonnes = this.form.carbon;

        if (this.form.unit === 'kg') {
          tonnes = this.form.carbon / 1000;
        } else if (this.form.unit === 'lbs') {
          // convert lbs to tonnes
          tonnes = this.form.carbon / 2204.62;
        }

        return {
          carbon: this.form.carbon,
          money: ((this.carbon.project.totalCostPerKgCarbonInUsdCents * (tonnes * 1000)) / 100).toFixed(2)
        };
      }
    },
    mapOptions() {
      return {
        center: { lat: parseFloat(this.carbon.project.coordinates.lat), lng: parseFloat(this.carbon.project.coordinates.lng) },
        zoom: 4,
        mapTypeId: 'hybrid'
      };
    },
    mapMarkers() {
      return [
        {
          pos: {
            lat: parseFloat(this.carbon.project.coordinates.lat),
            lng: parseFloat(this.carbon.project.coordinates.lng)
          },
          label: {
            text: '\uf111', // codepoint from https://fonts.google.com/icons
            fontFamily: '"Font Awesome 6 Pro"',
            fontWeight: '900',
            color: '#65c198',
            fontSize: '18px'
          },
          icon: {
            path:
              'M384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384z',
            fillColor: '#ffffff',
            fillOpacity: 1,
            strokeWeight: 3,
            strokeColor: '#65c198',
            rotation: 0,
            scale: 0.09,
            labelOrigin: [195, 200],
            anchor: [195, 520]
          },
          title: this.carbon.project.shortName
        }
      ];
    }
  },
  mounted() {
    this.getCarbonProject({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      getCarbonProject: 'marketplace/getCarbonProject'
    })
  }
};
</script>
<style scoped>
.image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
}
.image-sm {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}
.image-list {
  box-sizing: border-box;
  display: grid;
  grid-gap: 15px;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  justify-content: center;
}
</style>
