<template>
  <dl class="grid grid-cols-2 gap-4 text-muted">
    <dt class="text-muted"><i v-if="icon" class="fa fa-fw mr-1" :class="icon"></i> {{ label }}</dt>
    <dd class="text-muted font-w500 mb-0 pl-1" :class="{ 'text-uppercase': uppercase, 'text-capitalize': capitalize }">
      <slot>{{ blank ? '' : value }}</slot>
    </dd>
  </dl>
</template>
<script>
export default {
  name: 'TextListItem',
  props: {
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: 'N/A'
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    capitalize: {
      type: Boolean,
      default: false
    },
    blank: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style scoped>
dt {
  white-space: nowrap;
}
dt,
dd {
  line-height: 20px;
}
</style>
