<template>
  <div class="row no-gutters pb-2 mb-3 align-items-end SectionTitle">
    <div class="col">
      <h3 class="mb-0" :class="titleClass">
        <slot name="icon"><i v-if="icon" class="fa fa-sm text-primary mr-2" :class="icon"></i></slot>
        <slot>{{ title }}</slot>
      </h3>
    </div>
    <div v-if="$slots['actions']" class="text-right" :class="`${actionsClass}`">
      <slot name="actions"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SectionTitle',
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    icon: {
      type: String,
      default: ''
    },
    actionsClass: {
      type: String,
      default: 'col-md-3'
    },
    titleClass: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="scss">
@import '~@/assets/_scss/custom/variables';

.SectionTitle {
  border-bottom: 3px solid $gray-lines;
}

.SectionTitle h3 {
  line-height: 35px;
}
</style>
