<template>
  <div class="content">
    <div class="row align-items-center">
      <div class="col">
        <div class="">
          <p class="mb-2">
            <router-link v-if="categoryRoute" class="text-uppercase text-muted font-w600" :to="categoryRoute"
              ><i :class="`fa ${categoryLogo}`"></i> {{ category }}</router-link
            >
            <span v-else class="text-uppercase text-muted font-w600"><i :class="`fa ${categoryLogo}`"></i> {{ category }}</span>
          </p>
          <h1 class="mb-0">
            <slot>{{ title }}</slot>
          </h1>
          <p v-if="subTitle" class="text-size-h2 font-w500 mb-0">{{ subTitle }}</p>
        </div>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-6 text-right">
        <slot name="actions">
          <TextListItem v-if="company.name" icon="fa-user" label="Company" :value="company.name" />
          <router-link v-if="entity?.legalName" class="" :to="{ name: 'entity-view', params: { id: entity._id } }">
            <TextListItem icon="fa-briefcase" label="Entity" :value="entity.legalName" />
          </router-link>
          <router-link v-if="entity?.parentEntity" class="" :to="{ name: 'entity-view', params: { id: entity.parentEntity._id } }">
            <TextListItem icon="fa-briefcase" label="Parent Entity" :value="entity.parentEntity.legalName" />
          </router-link>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import TextListItem from '@/components/base/TextListItem';

export default {
  name: 'PageHeaderDisplay',
  components: {
    TextListItem
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    company: {
      type: Object,
      required: false,
      default: () => ({})
    },
    category: {
      type: String,
      required: false,
      default: 'Asset'
    },
    categoryLogo: {
      type: String,
      required: false,
      default: 'fa-building'
    },
    categoryRoute: {
      type: Object,
      required: false,
      default: null
    },
    entity: {
      type: Object,
      required: false,
      default: () => ({})
    },
    parentEntity: {
      type: Object,
      required: false,
      default: () => ({})
    },
    logo: {
      type: String,
      default: ''
    }
  },
  watch: {
    title() {
      document.title = `${this.title} | Etainabl`;
    }
  },
  mounted() {
    document.title = `${this.title} | Etainabl`;
  }
};
</script>
<style>
.PageHeaderDisplay {
  padding-bottom: 8px;
  border-bottom: 2px solid #65c198;
}
</style>
