<template>
  <div class="row">
    <div v-if="mapConfig" :class="{ 'col-6': !!localStreetViewConfig, 'col-12': !localStreetViewConfig }">
      <img v-if="isStatic && mapStaticUrl" class="static" :src="mapStaticUrl" />
      <div v-if="!mapStaticUrl || !streetViewStaticUrl" ref="googleMap" class="GoogleMap-map" :style="{ width: '100%', height: height + 'px' }"></div>
    </div>
    <div v-if="localStreetViewConfig" class="col-6">
      <img v-if="isStatic && streetViewStaticUrl" class="static" :src="streetViewStaticUrl" />
      <div
        v-if="!mapStaticUrl || !streetViewStaticUrl"
        ref="streetView"
        class="GoogleMap-streetview"
        :style="{ width: '100%', height: height + 'px' }"
      ></div>
    </div>
  </div>
</template>
<script>
import GoogleMapsApiLoader from 'google-maps-api-loader';

export default {
  name: 'GoogleMap',
  props: {
    mapConfig: {
      type: Object,
      default: null
    },
    streetViewConfig: {
      type: Object,
      default: null
    },
    geometry: {
      type: Array,
      required: false,
      default: () => []
    },
    height: {
      type: String,
      default: '400'
    },
    query: {
      type: String,
      default: null
    },
    markers: {
      type: Array,
      default: () => []
    },
    traffic: {
      type: Boolean,
      default: false
    },
    isStatic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localStreetViewConfig: { ...this.streetViewConfig },
      google: null,
      map: null,
      panorama: null,
      heading: 0,
      pitch: 10
    };
  },
  computed: {
    mapStaticUrl() {
      if (!this.isStatic) return null;

      return `https://maps.googleapis.com/maps/api/staticmap?center=${this.mapConfig.center.lat},${this.mapConfig.center.lng}&zoom=${
        this.mapConfig.zoom || 14
      }&size=800x400&scale=2&maptype=${this.mapConfig.mapTypeId || 'roadmap'}&key=${process.env.VUE_APP_GOOGLE_API_KEY}`;
    },
    streetViewStaticUrl() {
      if (!this.panorama) return null;

      const options = {
        size: '800x400',
        location: `${this.mapConfig.center.lat},${this.mapConfig.center.lng}`,
        heading: this.heading,
        pitch: this.pitch,
        key: process.env.VUE_APP_GOOGLE_API_KEY,
        source: 'outdoor'
      };

      const optionsString = Object.entries(options)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      return `https://maps.googleapis.com/maps/api/streetview?${optionsString}`;
    }
  },

  watch: {
    markers() {
      if (this.map) {
        this.updateMarkers();
      }
    }
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
      version: 'beta&map_ids=490d6a9e0a1ed398'
    });

    this.google = googleMapApi;

    this.initializeMap();
  },
  methods: {
    updateMarkers() {
      if (this.markers && this.markers.length > 0) {
        this.markers.forEach(marker => {
          if (marker.icon) {
            if (marker.icon.anchor) marker.icon.anchor = new this.google.maps.Point(...marker.icon.anchor);
            if (marker.icon.labelOrigin) marker.icon.labelOrigin = new this.google.maps.Point(...marker.icon.labelOrigin);
          }

          new this.google.maps.Marker({
            position: marker.pos,
            title: marker.title,
            label: marker.label,
            icon: marker.icon,
            map: this.map
          });
        });

        const markers = this.markers; //some array
        const bounds = new this.google.maps.LatLngBounds();
        for (var i = 0; i < markers.length; i++) {
          bounds.extend(markers[i].pos);
        }

        this.map.initialZoom = true;
        this.map.fitBounds(bounds);

        this.google.maps.event.addListener(this.map, 'bounds_changed', () => {
          if (this.map.getZoom() > this.mapConfig.zoom && this.map.initialZoom === true) {
            // Change max/min zoom here
            this.map.setZoom(this.mapConfig.zoom);
            this.map.initialZoom = false;
          }
        });
      }
    },
    mapOptions() {
      this.initializeMap();
    },
    async initializeMap() {
      const mapContainer = this.$refs.googleMap;

      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);

      if (this.traffic) {
        const trafficLayer = new this.google.maps.TrafficLayer();
        trafficLayer.setMap(this.map);
      }

      this.updateMarkers();

      if (this.query) {
        const latLng = await this.geocodeQuery(this.query);
        this.map.setCenter(latLng);
      }

      if (this.geometry) {
        this.geometry.forEach(item => {
          // Construct the polygons
          const polygon = new this.google.maps.Polygon({
            paths: item.coordinates,
            strokeColor: item.colour || '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: item.colour || '#FF0000',
            fillOpacity: 0.35
          });

          polygon.setMap(this.map);
        });
      }

      if (this.streetViewConfig) {
        const streetViewService = new this.google.maps.StreetViewService();
        const streetViewContainer = this.$refs.streetView;

        this.panorama = new this.google.maps.StreetViewPanorama(streetViewContainer, this.localStreetViewConfig);

        let targetLatLng = this.mapConfig.center;

        if (this.query) {
          const geoLatLng = await this.geocodeQuery(this.query);
          targetLatLng = { lat: geoLatLng.lat.toString(), lng: geoLatLng.lng.toString() };
        }
        streetViewService.getPanorama({ location: targetLatLng, radius: 50 }, (data, status) => {
          if (status === 'OK') {
            const panoLocation = data.location;

            this.panorama.setPano(panoLocation.pano);

            targetLatLng = new this.google.maps.LatLng(targetLatLng.lat, targetLatLng.lng);

            this.heading = this.google.maps.geometry.spherical.computeHeading(panoLocation.latLng, targetLatLng);

            this.panorama.setPov({
              heading: this.heading,
              pitch: 10
            });

            this.panorama.setVisible(true);
          } else {
            console.log('Street view could not find nearest location to target lat lng');
            this.localStreetViewConfig = null;
          }
        });
        this.map.setStreetView(this.panorama);
      }
    },
    async geocodeQuery(query) {
      const geocoder = new this.google.maps.Geocoder();

      return new Promise((resolve, reject) => {
        geocoder.geocode({ address: query }, (results, status) => {
          if (status === 'OK') {
            resolve(results[0].geometry.location);
          } else {
            reject('Geocode was not successful for the following reason: ' + status);
          }
        });
      });
    }
  }
};
</script>
<style scoped>
.static {
  width: 100%;
  height: auto;
  object-fit: contain;
}
</style>
