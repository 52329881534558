var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_vm.mapConfig ? _c('div', {
    class: {
      'col-6': !!_vm.localStreetViewConfig,
      'col-12': !_vm.localStreetViewConfig
    }
  }, [_vm.isStatic && _vm.mapStaticUrl ? _c('img', {
    staticClass: "static",
    attrs: {
      "src": _vm.mapStaticUrl
    }
  }) : _vm._e(), !_vm.mapStaticUrl || !_vm.streetViewStaticUrl ? _c('div', {
    ref: "googleMap",
    staticClass: "GoogleMap-map",
    style: {
      width: '100%',
      height: _vm.height + 'px'
    }
  }) : _vm._e()]) : _vm._e(), _vm.localStreetViewConfig ? _c('div', {
    staticClass: "col-6"
  }, [_vm.isStatic && _vm.streetViewStaticUrl ? _c('img', {
    staticClass: "static",
    attrs: {
      "src": _vm.streetViewStaticUrl
    }
  }) : _vm._e(), !_vm.mapStaticUrl || !_vm.streetViewStaticUrl ? _c('div', {
    ref: "streetView",
    staticClass: "GoogleMap-streetview",
    style: {
      width: '100%',
      height: _vm.height + 'px'
    }
  }) : _vm._e()]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }